import React from "react";
import "./TeacherRatings.css";

const TeacherRatings = () => {
  return (
    <>
      <div className="ratings">
        <div className="ratings_item">
          <div className="review">
            <div className="review_ratings">
              <img src="./images/stars.svg" alt="" />
            </div>
            <div className="review_ratings">4.5</div>
          </div>
          <div className="review_txt">Nice Teacher</div>
        </div>
        <div className="rating_desp">
          Minimum qualification required to be appointed as a pre-primary or
          primary school teacher in any Government or Private School. To move
          the level up to secondary and professor positions, one must complete
          one or more of the courses which are mentioned
        </div>
        <div className="rating_customer">
          <div className="customer_name">By Mayank G</div>
          <div className="customer_name">6 month ago</div>
        </div>
        <hr />
      </div>
    </>
  );
};

export default TeacherRatings;
