import React from "react";
import Button from "../../Button/Button";
import "./LiveSVG.css";
import { useNavigate } from "react-router-dom";

const LiveSVG = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="liveSVG">
        <img src="../images/live.png" alt="" />
        <div className="liveSVG_det">
          <div className="liveSVG_det_title">
            Live 1- on -1 Doubt Resolution
          </div>
          <ul className="liveSVG_det_ul">
            <li className="liveSVG_ul_text">Schedule your Class</li>
            <li className="liveSVG_ul_text">Individual mentor for you</li>
            <li className="liveSVG_ul_text">Video calling, chat any medium </li>
          </ul>
          <div className="liveSVG_btn">
            <Button
              width="13.2rem"
              height="3.5rem"
              text="Secdule Now"
              background="#ffffff"
              border="0.2rem solid #B0E5E9"
              event={() => {
                navigate("/mentor");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveSVG;
