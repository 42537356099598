import React from "react";
import "./Schedule.css";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";

const Schedule = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="schedule">
        <div className="schedule_det_l">
          <img src="../images/mentor_logo.png" alt="" />
          <div className="schedule_rating">
            <img src="../images/star.svg" alt="" />
            <div className="schedule_rate">4.5</div>
            <div className="schedule_customer">(20)</div>
          </div>
          {/*<div className="schedule_profile">View Profile</div>*/}
        </div>
        <div className="schedule_det_r">
          <div className="schedule_title">Saheb Biswas</div>
          <div className="schedule_txt">Phd. Math, 10 yrs. Exp.</div>
          <div className="schedule_btn">
            <Button
              width="11rem"
              height="3rem"
              background="#ffffff"
              border="2px solid #FF6652"
              text="Profile"
              borderRadius="1rem"
              event={() => {
                navigate(`/mentor-details`);
                // navigate(`/mentor-details/${id}`,{
                //   data: props
                // });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Schedule;
