import React, { useEffect } from "react";
import "./Mentor.css";
import Base from "../../components/Base/Base";
import Header from "../../components/Header/Header";
import Schedule from "../../components/Card/Schedule/Schedule";
// import { useDispatch, useSelector } from "react-redux";
// import { asyncMentor } from "../../redux/slices/genSlice";

const Mentor = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  // const dispatch = useDispatch();
  // const { mentorState } = useSelector((state) => state.gen);

  // useEffect(() => {
  //   dispatch(asyncMentor());
  // }, []);
  // console.log("mentorState", mentorState);
  return (
    <>
      <div className="container">
        <Base>
          <div className="mentor_cont">
            <div className="mentor_header">
              <Header title="Happy to help you" />
            </div>
            <div className="mentor_asign">
              <div className="mentor_asign_header">Our Mentors</div>
              <div className="mentor_asign_mentor">
                <Schedule />
                {/*{mentorState.data.map((item) => {
                  // console.log(item);
                  return <Schedule info={item} />;
                })}*/}
              </div>
            </div>
          </div>
        </Base>
      </div>
    </>
  );
};

export default Mentor;
