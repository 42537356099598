import React, { useState, useEffect } from "react";
import Base from "../../components/Base/Base";
import MockTestH from "../../components/MockTestH/MockTestH";
import Button from "../../components/Button/Button";
import parse from "html-react-parser";
import { saveQuestion, getByIndex } from "../../apiCalls/test_api";
import { useNavigate, useParams } from "react-router-dom";
import "./Update.css";
// import { useDispatch, useSelector } from "react-redux";
// import { asyncQuestionSave, asyncIndex } from "../../redux/slices/testSlice";

const Update = () => {
  const navigate = useNavigate();
  const { index, testID, qstatus } = useParams();
  // const dispatch = useDispatch();
  const [saveData, setSaveData] = useState({});
  const [show, setShow] = useState(false);
  const [option, setClickOption] = useState([]);
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  const getClickOption = async (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setClickOption([...option, value]);
    } else {
      setClickOption(option.filter((e) => e !== value));
    }
  };

  const setOptionData = (op) => {
    setClickOption([...op]);
    // console.log("Option---------", op);
  };

  // console.log(saveData?.current_question);

  const getIndex = () => {
    getByIndex(testID, index)
      .then((response) => {
        // console.log(response?.data);
        setSaveData(response?.data);
        let ops = [];
        response?.data?.current_question?.option?.map((item) => {
          if (item.selected_by_user == true) {
            ops.push(item?.id?.toString());
          }
          setOptionData(ops);
        });
      })
      .catch((error) => {
        // console.log(error);
      });
    // console.log(saveData);
  };

  // const getIndex = () => {
  //   dispatch(asyncIndex(testID, index));
  // };

  useEffect(() => {
    getIndex(testID, index);
  }, []);

  // const { indexState } = useSelector((state) => state.test);
  // console.log(indexState);

  const saveHandle = () => {
    saveQuestion(testID, {
      question: saveData?.current_question?.question?.id,
      option: option,
    })
      .then((response) => {
        // console.log(response.data);
        setSaveData(response.data);
        let ops = [];
        response?.data?.current_question?.option?.map((item) => {
          if (item.selected_by_user === true) {
            ops.push(item?.id?.toString());
          }
        });
        navigate(`/questionlist/${testID}/${qstatus}`);

        // console.log(ops);
        setOptionData(ops);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const questlist = `/questionlist/${testID}/${qstatus}`;
  // const saveHandle = () => {
  //   dispatch(asyncSave(testID));
  // };
  // const { saveState } = useSelector((state) => state.test);
  // console.log(saveState);

  return (
    <>
      <div className="container">
        <Base>
          <div className="mock_cont">
            <div className="mock_mockh">
              <MockTestH nav={questlist} info={saveData} />
            </div>
            <div className="mock_test">
              <div className="test">
                <div className="test_ques_no">
                  Question No.
                  {saveData?.current_question?.index}
                </div>
                <div className="test_ques">
                  {parse(
                    saveData?.current_question?.question?.question_text
                      ? saveData?.current_question?.question?.question_text
                      : ""
                  )}
                </div>
                <div className="test_option">
                  {saveData?.current_question?.option?.map((item) => {
                    return (
                      <div className="test_check" element={item} key={item?.id}>
                        <input
                          type="checkbox"
                          className="test_check_input"
                          value={item?.id}
                          defaultChecked={item.selected_by_user}
                          onChange={(e) => {
                            getClickOption(e);
                          }}
                        />
                        <label className="test_check_label">
                          <span>{item?.answer_sequence}</span>
                          {parse(item?.answer_text)}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <div className="test_show">
                  {show ? (
                    <div className="test_show_t">
                      <p
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        Show
                      </p>
                    </div>
                  ) : (
                    <div className="test_hide_t">
                      <p
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        Show
                      </p>
                    </div>
                  )}
                  {show ? (
                    <div className="test_hide_t">
                      <p
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        Hide
                      </p>
                    </div>
                  ) : (
                    <div className="test_show_t">
                      <p
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        Hide
                      </p>
                    </div>
                  )}
                </div>
                {show ? (
                  <div className="test_expl">
                    <div className="test_txt">
                      {saveData?.current_question?.correct_answer.map(
                        (item) => {
                          return (
                            <div className="test_text">
                              <span>{item?.answer_sequence}.</span>
                              {parse(item?.answer_text)}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mock_btn">
              <Button
                width="30rem"
                height="3rem"
                background="#404DBF"
                border="1px solid #404DBF"
                text="Save"
                color="#ffffff"
                borderRadius="0.5rem"
                event={() => {
                  saveHandle();
                }}
              />
            </div>
          </div>
        </Base>
      </div>
    </>
  );
};

export default Update;
