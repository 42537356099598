import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../../redux/slices/authSlice";
import "./Navbar.css";
import Button from "../Button/Button";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const { loginState } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log(loginState);
  const handleOpen = () => {
    setOpen(!open);
  };

  // console.log("localStorage", localStorage.getItem("token"));

  return (
    <>
      <div className="navbar">
        <div className="nav">
          <div className="nav_logo">
            <NavLink to="/">
              <img src="../images/RecolPark.png" alt="" />
            </NavLink>
          </div>
          <div className="nav_items">
            <div className="nav_item">
              {
                loginState.token === null &&
                localStorage.getItem("token") === null ? (
                  <Button
                    width="8rem"
                    text="Sign In"
                    height="2.7rem"
                    fontWeight="500"
                    event={() => {
                      navigate("/signin");
                    }}
                  />
                ) : (
                  <div className="hamburger-menu">
                    <label htmlFor="toggle">&#9776;</label>
                    <input type="checkbox" id="toggle" onClick={handleOpen} />
                    {open ? (
                      <div className="dropdown">
                        <ul className="dropdown-content">
                          <li>
                            <img src="./images/dashboard.svg" alt="" />
                            <NavLink to="/account">Account</NavLink>
                          </li>
                          <li>
                            <img src="./images/logout.svg" alt="" />
                            <NavLink
                              to="/"
                              onClick={() => {
                                localStorage.removeItem("userToken");
                                localStorage.removeItem("token");
                                dispatch(logOut());
                                window.location.href = "/";
                              }}
                            >
                              Logout
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  </div>
                )
                //<Button
                // width="8rem"
                //text="Logout"
                //height="2.7rem"
                //fontWeight="500"
                //event={() => {
                //localStorage.removeItem("userToken");
                //dispatch(logOut());
                //}}
                ///>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
