import React, { useEffect, useState } from "react";
import "./MockFree.css";
import parse from "html-react-parser";
import Base from "../../components/Base/Base";
import Button from "../../components/Button/Button";
import { useNavigate, useLocation } from "react-router-dom";

// import MockTestHFree from "../../components/MockTestHFree/MockTestHFree";
const MockFree = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let data = location.state.data;
  const [show, setShow] = useState(false);
  const [option, setClickOption] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(
    localStorage.getItem("currentIndex")
  );
  // const [current, setCurrent] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState({});

  useEffect(() => {
    getCurrentQuestion();
  }, [currentIndex]);

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  const getCurrentQuestion = () => {
    let qlist = JSON.parse(localStorage.getItem("mockData"));
    let qs = qlist.questions[currentIndex];
    setCurrentQuestion(qs);
    console.log(qs);
  };

  const updateCurrentIndex = (idx) => {
    setCurrentIndex(idx);
    localStorage.setItem("currentIndex", idx);
  };

  const getClickOption = async (e, params, idx) => {
    console.log(params, e);
    const { value, checked } = e.target;
    if (checked == true) {
      setClickOption([...option, value]);
      currentQuestion.question_option[idx].selected_by_user = true;

      // console.log("params", params);
    } else {
      setClickOption(option.filter((e) => e !== value));
      currentQuestion.question_option[idx].selected_by_user = false;
    }
    setCurrentQuestion(currentQuestion);
  };

  const nextHandle = () => {
    let mockData = JSON.parse(localStorage.getItem("mockData"));
    mockData.questions[currentIndex] = currentQuestion;

    localStorage.setItem("mockData", JSON.stringify(mockData));
    if (parseInt(currentIndex) < data.questions.length - 1) {
      updateCurrentIndex(parseInt(currentIndex) + 1);
      getCurrentQuestion();
    }
  };

  const previousHandle = () => {
    if (parseInt(currentIndex) > 0) {
      updateCurrentIndex(parseInt(currentIndex) - 1);
      getCurrentQuestion();
    }
  };

  const submitHandle = () => {
    let marks = 0;
    let mockData = JSON.parse(localStorage.getItem("mockData"));
    for (let i = 0; i < mockData.questions.length; i++) {
      let select = [];
      let correct = [];
      for (let j = 0; j < mockData.questions[i].question_option.length; j++) {
        // console.log("option", i, j);

        if (mockData.questions[i].question_option[j].selected_by_user == true) {
          select.push(mockData.questions[i].question_option[j].id);
        }
        if (mockData.questions[i].question_option[j].correct == true) {
          correct.push(mockData.questions[i].question_option[j].id);
        }
        // console.log(correct);
      }
      var isEqual =
        JSON.stringify(select.sort()) === JSON.stringify(correct.sort());
      if (isEqual === true) {
        console.log(
          JSON.stringify(select.sort()),
          JSON.stringify(correct.sort())
        );
        marks = marks + parseInt(mockData.questions[i].marks);
      }
    }
    console.log("marks", marks);
    mockData.score = marks;
    localStorage.setItem("mockData", JSON.stringify(mockData));
    navigate(`/answers/`, { state: { data: data, marks: marks } });
  };

  // console.log("getCurrentIndex", getCurrentIndex);
  // console.log("data_", data.questions[0].marks);

  return (
    <>
      <div className="container">
        <Base>
          <div className="mock_cont">
            <div className="mock_mockh">
              {/*<MockTestHFree nav="/filter" info={mockData} />*/}
            </div>
            <div className="mock_test">
              <div className="test">
                <div className="test_ques_no">
                  Question No. {parseInt(currentIndex) + 1}
                </div>
                <div className="test_ques">
                  {parse(
                    currentQuestion?.question_text
                      ? currentQuestion?.question_text
                      : ""
                  )}
                </div>
                <div className="test_option">
                  {currentQuestion?.question_option?.map((item, index) => {
                    // console.log("checkbox", item);
                    return (
                      <div className="test_check" key={item?.id}>
                        <input
                          type="checkbox"
                          className="test_check_input"
                          value={item?.id}
                          defaultChecked={item?.selected_by_user}
                          onChange={(e) => {
                            getClickOption(e, item, index);
                          }}
                        />
                        <label className="test_check_label">
                          <span>{item?.answer_sequence}</span>
                          {parse(item?.answer_text)}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <div className="test_show">
                  {show ? (
                    <div className="test_show_t">
                      <p
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        Show
                      </p>
                    </div>
                  ) : (
                    <div className="test_hide_t">
                      <p
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        Show
                      </p>
                    </div>
                  )}
                  {show ? (
                    <div className="test_hide_t">
                      <p
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        Hide
                      </p>
                    </div>
                  ) : (
                    <div className="test_show_t">
                      <p
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        Hide
                      </p>
                    </div>
                  )}
                </div>
                {show ? (
                  <div className="test_expl">
                    <div className="test_txt">
                      {data?.questions[currentIndex]?.question_option?.map(
                        (item) => {
                          if (item?.correct == true) {
                            return (
                              <div className="test_text" key={item?.id}>
                                <span>{item?.answer_sequence}</span>
                                {parse(item?.answer_text)}
                              </div>
                            );
                          }
                        }
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mock_btn">
              <Button
                width="13.8rem"
                height="3.2rem"
                background="#ffffff"
                border="1px solid #5D5A6F"
                text="Previous"
                borderRadius="0.5rem"
                event={() => {
                  previousHandle();
                }}
              />

              <Button
                width="13.8rem"
                height="3.2rem"
                background="#ffffff"
                border="1px solid #5D5A6F"
                text="Next"
                borderRadius="0.5rem"
                event={() => {
                  nextHandle();
                }}
              />
              <Button
                width="13.8rem"
                height="3.2rem"
                background="#ffffff"
                border="1px solid #5D5A6F"
                text="Submit"
                borderRadius="0.5rem"
                event={() => {
                  submitHandle();
                }}
              />
            </div>
          </div>
        </Base>
      </div>
    </>
  );
};

export default MockFree;
