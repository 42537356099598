import React from "react";
import { Routes, Route } from "react-router-dom";
import OTP from "../pages/OTP/OTP";
import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import Terms from "../pages/Terms/Terms";
import Filter from "../pages/Filter/Filter";
import Mentor from "../pages/Mentor/Mentor";
import SignUp from "../pages/Signup/Signup";
import SignIn from "../pages/Signin/Signin";
import Update from "../pages/Update/Update";
import Policy from "../pages/Policy/Policy";
import Account from "../pages/Account/Account";
import MockTest from "../pages/MockTest/MockTest";
import MockFree from "../pages/MockFree/MockFree";
import ForgotPass from "../pages/ForgotPass/ForgotPass";
import TestSummary from "../pages/TestSummary/TestSummary";
import FilterNoLog from "../pages/FilterNoLog/FilterNoLog";
import AnswerSheet from "../pages/AnswerSheet/AnswerSheet";
import QuestionList from "../pages/QuestionList/QuestionList";
import MentorDetails from "../pages/MentorDetails/MentorDetails";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import AnswerSheetNoLog from "../pages/AnswerSheetNoLog/AnswerSheetNoLog";

const Routings = () => {
  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="/otp" element={<OTP />} />
      <Route path="/about" element={<About />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/filter" element={<Filter />} />
      <Route path="/mentor" element={<Mentor />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/account" element={<Account />} />
      <Route path="/mock-test" element={<MockTest />} />
      <Route path="/mock-tests" element={<MockFree />} />
      <Route path="/filters" element={<FilterNoLog />} />
      <Route path="/privacy_policy" element={<Policy />} />
      <Route path="/forgotpass" element={<ForgotPass />} />
      <Route path="/summary/:testID" element={<TestSummary />} />
      <Route path="/mentor-details" element={<MentorDetails />} />
      <Route path="/changepass/:emailID" element={<ChangePassword />} />
      <Route path="/update/:testID/:index/:qstatus" element={<Update />} />
      <Route path="/questionlist/:testID/:qstatus" element={<QuestionList />} />
      <Route
        path="/answer/:testID/:no_of_qs/:total_marks"
        element={<AnswerSheet />}
      />
      <Route path="/answers/" element={<AnswerSheetNoLog />} />
    </Routes>
  );
};

export default Routings;
