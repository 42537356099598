import React, { useState, useEffect } from "react";
import "./MockTest.css";
import parse from "html-react-parser";
import Base from "../../components/Base/Base";
import Button from "../../components/Button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import MockTestH from "../../components/MockTestH/MockTestH";
import {
  nextQS,
  previousQS,
  skipQS,
  reviewQS,
  currentQS,
  answer_reviewQS,
} from "../../apiCalls/test_api";

const MockTest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const testID = location.state.data.id;
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [option, setClickOption] = useState([]);

  const navigateToSummary = (testID) => {
    navigate(`/summary/${testID}`, {
      state: { data: testID },
    });
  };

  const getClickOption = async (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setClickOption([...option, value]);
    } else {
      setClickOption(option.filter((e) => e !== value));
    }
    // console.log(option);
  };

  const setOptionData = (op) => {
    setClickOption([...op]);
    // console.log("Option_op---------", op);
  };

  useEffect(() => {
    currentHandle(testID);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const nextHandle = () => {
    if (data.current_question.last) {
      nextQS(data.id, {
        question: data.current_question.question.id,
        option: option,
      })
        .then((response) => {
          setData(response.data);
          // console.log(response.data.success.message);
          setClickOption([]);
        })
        .catch((error) => {
          // console.log(error.response.data[0]);
        });
      navigateToSummary(data.id);
    } else {
      nextQS(data.id, {
        question: data.current_question.question.id,
        option: option,
      })
        .then((response) => {
          setData(response.data);

          let ops = [];
          response?.data?.current_question?.option?.map((item) => {
            if (item.selected_by_user === true) {
              ops.push(item?.id?.toString());
            }
            setOptionData(ops);
          });

          // console.log("next_opt", ops);
        })
        .catch((error) => {
          // console.log(error.response);
        });
    }
  };

  const currentHandle = () => {
    currentQS(testID)
      .then((response) => {
        setData(response.data);
        let ops = [];
        response?.data?.current_question?.option?.map((item) => {
          if (item.selected_by_user) {
            ops.push(item?.id?.toString());
          }
        });

        // console.log(ops);
        setOptionData(ops);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  const previousHandle = () => {
    previousQS(data.id, {
      question: data.current_question.question.id,
    })
      .then((response) => {
        setData(response.data);
        let ops = [];
        response?.data?.current_question?.option?.map((item) => {
          if (item.selected_by_user) {
            ops.push(item?.id?.toString());
          }
        });

        // console.log("mocktest_ops", ops);
        setOptionData(ops);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  const skipHandle = () => {
    if (data.current_question.last) {
      skipQS(data.id, {
        question: data.current_question.question.id,
      })
        .then((response) => {
          setData(response.data);
          // toast.success(response.data.success.message);
        })
        .catch((error) => {
          // console.log(error.response);
        });
      navigateToSummary(data.id);
    } else {
      skipQS(data.id, {
        question: data.current_question.question.id,
      })
        .then((response) => {
          setData(response.data);
          // toast.success(response.data.success.message);
        })
        .catch((error) => {
          // console.log(error.response);
        });
    }
  };

  const reviewHandle = () => {
    if (option.length == 0) {
      if (data.current_question.last) {
        reviewQS(data.id, {
          question: data.current_question.question.id,
        })
          .then((response) => {
            setData(response.data);
            setClickOption([]);
          })
          .catch((error) => {
            // console.log(error.response.data[0]);
          });
        navigateToSummary(data.id);
      } else {
        reviewQS(data.id, {
          question: data.current_question.question.id,
        })
          .then((response) => {
            setData(response.data);
            // console.log(response.data);
            let ops = [];
            response?.data?.current_question?.option?.map((item) => {
              if (item.selected_by_user === true) {
                ops.push(item?.id?.toString());
              }
              setOptionData(ops);
            });
            // console.log("review_opt", ops);
          })
          .catch((error) => {
            // console.log(error.response);
          });
      }
    } else {
      if (data.current_question.last) {
        answer_reviewQS(data.id, {
          question: data.current_question.question.id,
          // option: option,
        })
          .then((response) => {
            setData(response.data);
            setClickOption([]);
          })
          .catch((error) => {
            // console.log(error.response.data[0]);
          });
        navigateToSummary(data.id);
      } else {
        answer_reviewQS(data.id, {
          question: data.current_question.question.id,
          option: option,
        })
          .then((response) => {
            setData(response.data);
            // console.log(response.data);
            let ops = [];
            response?.data?.current_question?.option?.map((item) => {
              if (item.selected_by_user === true) {
                ops.push(item?.id?.toString());
              }
              setOptionData(ops);
            });
            // console.log("review_opt", ops);
          })
          .catch((error) => {
            // console.log(error.response);
          });
      }
    }
  };
  return (
    <>
      <div className="container">
        <Base>
          <div className="mock_cont">
            <div className="mock_mockh">
              <MockTestH nav="/filter" info={data} />
            </div>
            <div className="mock_test">
              <div className="test">
                <div className="test_ques_no">
                  Question No. {data?.current_question?.index}
                </div>
                <div className="test_ques">
                  {parse(
                    data?.current_question?.question?.question_text
                      ? data?.current_question?.question?.question_text
                      : ""
                  )}
                </div>
                <div className="test_option">
                  {data?.current_question?.option?.map((item) => {
                    // console.log(item);
                    return (
                      <div className="test_check" key={item?.id} element={item}>
                        <input
                          type="checkbox"
                          className="test_check_input"
                          value={item?.id}
                          defaultChecked={item.selected_by_user}
                          onChange={(e) => {
                            getClickOption(e);
                          }}
                        />
                        <label className="test_check_label">
                          <span>{item.answer_sequence}</span>
                          {parse(item.answer_text)}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <div className="test_show">
                  {show ? (
                    <div className="test_show_t">
                      <p
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        Show
                      </p>
                    </div>
                  ) : (
                    <div className="test_hide_t">
                      <p
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        Show
                      </p>
                    </div>
                  )}
                  {show ? (
                    <div className="test_hide_t">
                      <p
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        Hide
                      </p>
                    </div>
                  ) : (
                    <div className="test_show_t">
                      <p
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        Hide
                      </p>
                    </div>
                  )}
                </div>
                {show ? (
                  <div className="test_expl">
                    <div className="test_txt">
                      {data?.current_question?.correct_answer.map((item) => {
                        return (
                          <div className="test_text" key={item.id}>
                            <span>{item.answer_sequence}.</span>
                            {parse(item.answer_text)}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mock_btn">
              {data?.current_question?.first ? null : (
                <Button
                  width="13.8rem"
                  height="3.2rem"
                  background="#ffffff"
                  border="1px solid #5D5A6F"
                  text="Previous"
                  borderRadius="0.5rem"
                  event={() => {
                    previousHandle();
                  }}
                />
              )}
              <Button
                width="13.8rem"
                height="3.2rem"
                background="#ffffff"
                border="1px solid #5D5A6F"
                text="Next"
                borderRadius="0.5rem"
                event={() => {
                  nextHandle();
                }}
              />
              <Button
                width="13.8rem"
                height="3.2rem"
                background="#ffffff"
                border="1px solid #5D5A6F"
                text="Skip"
                borderRadius="0.5rem"
                event={() => {
                  skipHandle();
                }}
              />
              <Button
                width="13.8rem"
                height="3.2rem"
                background="#ffffff"
                border="1px solid #5D5A6F"
                text="Review"
                borderRadius="0.5rem"
                event={() => {
                  reviewHandle();
                }}
              />
              {/*}
              <Button
                width="13.8rem"
                height="3.2rem"
                background="#ffffff"
                border="1px solid #5D5A6F"
                text="Answer and Review"
                borderRadius="0.5rem"
                event={() => {
                  reviewAnswerHandle();
                }}
              />
              */}
            </div>
          </div>
        </Base>
      </div>
    </>
  );
};

export default MockTest;
