import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  currentQS,
  summaryT,
  questionListByStatus,
  submitTest,
  saveTest,
  saveQuestion,
  getByIndex,
} from "../../apiCalls/test_api";

export const STATUSES = Object.freeze({
  IDLE: "idle",
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

const initialState = {
  mockState: {
    data: [],
    status: STATUSES.IDLE,
  },
  summaryState: {
    data: [],
    status: STATUSES.IDLE,
  },
  qlistState: {
    data: [],
    status: STATUSES.IDLE,
  },
  saveState: {
    data: [],
    status: STATUSES.IDLE,
  },
  submitState: {
    data: [],
    status: STATUSES.IDLE,
  },
  saveQuestionState: {
    data: [],
    status: STATUSES.IDLE,
  },
  indexState: {
    data: [],
    status: STATUSES.IDLE,
  },
};

export const asyncCurrentQS = createAsyncThunk("current", async (testID) => {
  const res = await currentQS(testID);
  return res.data;
});

export const asyncSummary = createAsyncThunk("summary", async (testID) => {
  const res = await summaryT(testID);
  return res.data;
});

export const asyncSave = createAsyncThunk("save", async (testID) => {
  const res = await saveTest(testID);
  // console.log(res, "createAsyncThunk_asyncSave----_____");
  return res.data;
});

export const asyncSubmit = createAsyncThunk("submit", async (testID) => {
  const res = await submitTest(testID);
  // console.log(res, "createAsyncThunk_asyncSubmit----_____");
  return res.data;
});

export const asyncIndex = createAsyncThunk("index", async (params) => {
  const res = await getByIndex(params.testID, params.index);
  // console.log(res, "createAsyncThunk_INDEX----_____");
  return res.data;
});

export const asyncQList = createAsyncThunk("qlist", async (params) => {
  const res = await questionListByStatus(params.testID, params.status);
  return res.data;
});

export const asyncQuestionSave = createAsyncThunk(
  "saveQuestion",
  async (params) => {
    const res = await saveQuestion(params.testID, params.test);
    // console.log(res, "createAsyncThunk_asyncSave----_____");
    return res.data;
  }
);

const testSlice = createSlice({
  name: "test",
  initialState,
  reducers: {
    addCurrent(state, action) {
      state.currentState.data = [action.payload, ...state.currentState.data];
    },
    addSummary(state, action) {
      state.summaryState.data = [action.payload, ...state.summaryState.data];
    },
    addQList(state, action) {
      state.qlistState.data = [action.payload, ...state.qlistState.data];
    },
    addSave(state, action) {
      state.saveState.data = [action.payload, ...state.saveState.data];
    },
    addSubmit(state, action) {
      state.submitState.data = [action.payload, ...state.submitState.data];
    },
    addSaveQuestion(state, action) {
      state.saveQuestionState.data = [
        action.payload,
        ...state.saveQuestionState.data,
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncCurrentQS.pending, (state) => {
        state.mockState.status = STATUSES.LOADING;
      })
      .addCase(asyncCurrentQS.fulfilled, (state, action) => {
        state.mockState.data = action.payload;
        state.mockState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncCurrentQS.rejected, (state) => {
        state.mockState.status = STATUSES.ERROR;
      });
    builder
      .addCase(asyncSummary.pending, (state) => {
        state.summaryState.status = STATUSES.LOADING;
      })
      .addCase(asyncSummary.fulfilled, (state, action) => {
        state.summaryState.data = action.payload;
        state.summaryState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncSummary.rejected, (state) => {
        state.summaryState.status = STATUSES.ERROR;
      });
    builder
      .addCase(asyncQList.pending, (state) => {
        state.qlistState.status = STATUSES.LOADING;
      })
      .addCase(asyncQList.fulfilled, (state, action) => {
        state.qlistState.data = action.payload;
        state.qlistState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncQList.rejected, (state) => {
        state.qlistState.status = STATUSES.LOADING;
      });
    builder
      .addCase(asyncSave.pending, (state) => {
        state.saveState.status = STATUSES.LOADING;
      })
      .addCase(asyncSave.fulfilled, (state, action) => {
        state.saveState.data = action.payload;
        state.saveState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncSave.rejected, (state) => {
        state.saveState.status = STATUSES.LOADING;
      });
    builder
      .addCase(asyncSubmit.pending, (state) => {
        state.submitState.status = STATUSES.LOADING;
      })
      .addCase(asyncSubmit.fulfilled, (state, action) => {
        state.submitState.data = action.payload;
        state.submitState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncSubmit.rejected, (state) => {
        state.submitState.status = STATUSES.LOADING;
      });
    builder
      .addCase(asyncQuestionSave.pending, (state) => {
        state.saveQuestionState.status = STATUSES.LOADING;
      })
      .addCase(asyncQuestionSave.fulfilled, (state, action) => {
        state.saveQuestionState.data = action.payload;
        state.saveQuestionState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncQuestionSave.rejected, (state) => {
        state.saveQuestionState.status = STATUSES.LOADING;
      });
    builder
      .addCase(asyncIndex.pending, (state) => {
        state.indexState.status = STATUSES.LOADING;
      })
      .addCase(asyncIndex.fulfilled, (state, action) => {
        state.saveQuestionState.data = action.payload;
        state.indexState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncIndex.rejected, (state) => {
        state.indexState.status = STATUSES.LOADING;
      });
  },
});

export const {
  addMock,
  addSummary,
  addQList,
  addSave,
  addSubmit,
  addSaveQuestion,
} = testSlice.actions;
export default testSlice.reducer;
