import React from "react";
import Base from "../../components/Base/Base";
import "./Policy.css";

const Privacy = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  return (
    <>
      <div className="container">
        <Base>
          <div className="policy">
            <div className="policy_heading">Policy</div>
            <div className="policy_para">
              <div className="policy_title">Privacy Policy</div>
              <div className="policy_descrip">
                At RecolPark, accessible from RecolPark (.com) , one of our main
                priorities is the privacy of our visitors. This Privacy Policy
                document contains types of information that is collected and
                recorded by RecolPark and how we use it. {"\n"}If you have
                additional questions or require more information about our
                Privacy Policy, do not hesitate to contact us. {"\n"}This
                Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in TitasMLS. This
                policy is not applicable to any information collected offline or
                via channels other than this website.
              </div>
            </div>
            <div className="policy_para">
              <div className="policy_title">Information We Collect</div>
              <div className="policy_descrip">
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to
                you at the point we ask you to provide your personal
                information. {"\n"}If you contact us directly, we may receive
                additional information about you such as your name, email
                address, phone number, the contents of the message and/or
                attachments you may send us, and any other information you may
                choose to provide.{"\n"}When you register for an Account, we may
                ask for your contact information, including items such as name,
                company name, address, email address, and telephone number.
              </div>
            </div>
            <div className="policy_para">
              <ul>
                <div className="policy_title">Third Party Privacy Policies</div>
                <li className="policy_description">
                  RecolPark's Privacy Policy does not apply to other advertisers
                  or websites. Thus, we are advising you to consult the
                  respective Privacy Policies of these third-party ad servers
                  for more detailed information. It may include their practices
                  and instructions about how to opt-out of certain options.
                </li>
                <li className="policy_description">
                  You can choose to disable cookies through your individual
                  browser options. To know more detailed information about
                  cookie management with specific web browsers, it can be found
                  at the browsers' respective websites.
                </li>
              </ul>
            </div>
            <div className="policy_para">
              <ul>
                <div className="policy_title">Children's Information</div>
                <li className="policy_description">
                  Another part of our priority is adding protection for children
                  while using the internet. We encourage parents and guardians
                  to observe, participate in, and/or monitor and guide their
                  online activity.
                </li>
                <li className="policy_description">
                  RecolPark does not knowingly collect any Personal Identifiable
                  Information from children under the age of 13. If you think
                  that your child provided this kind of information on our
                  website, we strongly encourage you to contact us immediately
                  and we will do our best efforts to promptly remove such
                  information from our records.
                </li>
              </ul>
            </div>
            <div className="policy_para">
              <ul>
                <div className="policy_title">How We Use Your Information</div>
                <li className="policy_description">
                  To provide you with the services you request, such as taking
                  tests and receiving test results;
                </li>
                <li className="policy_description">
                  To improve our website and services;
                </li>
              </ul>
            </div>
            <div className="policy_para">
              <div className="policy_title">
                Google Double Click DART Cookie
              </div>
              <div className="policy_descrip">
                Google is one of a third-party vendor on our site. It also uses
                cookies, known as DART cookies, to serve ads to our site
                visitors based upon their visit to www.website.com and other
                sites on the internet. However, visitors may choose to decline
                the use of DART cookies by visiting the Google ad and content
                network Privacy Policy at the following URL –
                <a href="https://policies.google.com/technologies/ads">
                  https://policies.google.com/technologies/ads
                </a>
              </div>
            </div>
            <div className="policy_para">
              <ul>
                <div className="policy_title">Sharing Your Information</div>
                <li className="policy_description">
                  Our third-party service providers, who help us operate our
                  website and services;
                </li>
                <li className="policy_description">
                  Advertisers, who may show you ads on our website or other
                  websites;
                </li>
                <li className="policy_description">
                  Analytics providers, who help us track and analyze website
                  traffic;
                </li>
              </ul>
            </div>
            <div className="policy_para">
              <ul>
                <div className="policy_title">
                  How We Protect Your Information
                </div>
                <li className="policy_description">
                  Using secure servers to store your personal information;
                </li>
                <li className="policy_description">
                  Using encryption to protect your personal information during
                  transmission;
                </li>
                <li className="policy_description">
                  Requiring our employees to keep your personal information
                  confidential.
                </li>
              </ul>
            </div>
          </div>
        </Base>
      </div>
    </>
  );
};

export default Privacy;
