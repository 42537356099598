import React from "react";
import "./Loader.css";

const Loader = ({ show }) => {
  return (
    show && (
      <>
        <div className="loader">
          <div className="loadingio-spinner-spinner-vxs5vj4ce0o">
            <div className="boom">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Loader;
