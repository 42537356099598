import React from "react";
import "./ChatSVG.css";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";

const ChatSVG = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="ChatSVG">
        <div className="ChatSVG_img">
          <img src="../images/young.png" alt="" />
        </div>
        <div className="CharSVG_det">
          <img src="../images/whatsapp.png" alt="" />
          <div className="ChatSVG_det_title">
            Instant Chat With Mentor on whatsapp
          </div>
          <div className="ChatSVG_det_text">
            Chat with our mentor directly via Whatsapp
          </div>
          <div className="ChatSVG_det_btn">
            <Button
              text="Chat Now"
              event={() => {
                navigate("/");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatSVG;
