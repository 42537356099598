import React from "react";
import "./MentorC.css";

const MentorC = () => {
  return (
    <>
      <div className="mentorc">
        <div className="mentorc_img">
          <img src="../images/mentor.png" alt="" />
        </div>
        <div className="mentorc_det">
          <div className="mentorc_title">Saheb Biswas</div>
          <div className="mentorc_txt">Phd. Math, 10 yrs. Exp.</div>
          <div className="mentorc_rating">
            <img src="../images/star.svg" alt="" />
            <div className="mentorc_rate">4.5</div>
            <div className="mentorc_customer">(20)</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MentorC;
