import React, { useState } from "react";
import parse from "html-react-parser";
import "./FreeAnswer.css";

const TestAnswer = (props) => {
  const [show, setShow] = useState(false);
  // console.log(props);
  return (
    <>
      <div className="test" key={props.info.id}>
        <div className="test_ques_no">Question No. {props?.index + 1}</div>
        <div className="test_ques">
          {parse(props?.info?.question_text ? props?.info?.question_text : "")}
        </div>
        <div className="test_option">
          {props?.info?.question_option?.map((item) => {
            return (
              <div className="test_check" key={item.id}>
                <input
                  type="checkbox"
                  className="test_check_input"
                  value={item?.id}
                  readOnly
                  checked={item.selected_by_user}
                />
                <label className="test_check_label">
                  <span>{item.answer_sequence}</span>
                  {parse(item.answer_text)}
                </label>
              </div>
            );
          })}
        </div>
        <div className="test_show">
          {show ? (
            <div className="test_show_t">
              <p
                onClick={() => {
                  setShow(true);
                }}
              >
                Show
              </p>
            </div>
          ) : (
            <div className="test_hide_t">
              <p
                onClick={() => {
                  setShow(true);
                }}
              >
                Show
              </p>
            </div>
          )}
          {show ? (
            <div className="test_hide_t">
              <p
                onClick={() => {
                  setShow(false);
                }}
              >
                Hide
              </p>
            </div>
          ) : (
            <div className="test_show_t">
              <p
                onClick={() => {
                  setShow(false);
                }}
              >
                Hide
              </p>
            </div>
          )}
        </div>
        {show ? (
          <div className="test_expl">
            <div className="test_txt">
              {props?.info?.question_option.map((item) => {
                if (item.correct == true) {
                  return (
                    <div className="test_text" key={item.id}>
                      <span>{item.answer_sequence}.</span>
                      {parse(item.answer_text)}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TestAnswer;
