import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  signUp,
  signIn,
  otp,
  getUserData,
  googleLogin,
} from "../../apiCalls/auth_api";

export const STATUSES = Object.freeze({
  IDLE: "idle",
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

const initialState = {
  forgot: {
    data: {},
  },
  userData: {},
  loginState: {
    data: {},
    message: [],
    token: localStorage.getItem("userToken"),
    status: STATUSES.IDLE,
    loading: STATUSES.LOADING,
  },
  otpState: {
    data: {},
    message: {},
    status: STATUSES.IDLE,
  },
  signUpData: {
    data: {},
    message: {},
    status: STATUSES.IDLE,
  },
  googleState: {
    data: {},
    status: STATUSES.IDLE,
  },
};

export const login = createAsyncThunk(
  "user/login",
  async (params, ThunkApi) => {
    const res = await signIn(params);
    if (
      res.data !== undefined &&
      (res.data.token !== null || res.data.token !== "")
    ) {
      localStorage.setItem("userToken", res.data.token);
      localStorage.setItem("userId", res.data.id);
      ThunkApi.dispatch(setToken(res.data.token));
      return res.data;
    } else {
      ThunkApi.dispatch(setLoginRejected(res.response.data));
      return res.response.data;
    }
  }
);

export const register = createAsyncThunk("user/register", async (params) => {
  const res = await signUp(params);
  // console.log('response----------', res.data);
  return res;
});

export const otpVerify = createAsyncThunk(
  "user/otp",
  async (params, ThunkApi) => {
    const res = await otp(params);
    // console.log('response----------', res.data);
    return res;
  }
);

export const fetchUserData = createAsyncThunk(
  "user/userData",
  async (params) => {
    const res = await getUserData(params);
    // console.log('userData----------', res.data);
    return res.data;
  }
);

export const asyncGoogle = createAsyncThunk("user/google", async () => {
  const res = await googleLogin();
  // console.log('response----------', res.data);
  return res.data;
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action) {
      state.loginState.status = STATUSES.SUCCESS;
      state.loginState.token = action.payload;
    },
    setLoginRejected(state, action) {
      state.loginState.status = STATUSES.ERROR;
      state.loginState.message = action.payload;
    },
    logOut(state) {
      state.loginState.data = [];
      state.loginState.token = "";
    },
    setUserData(state, action) {
      state.signUpData.data = action.payload;
    },
    resetOtpState(state) {
      state.otpState.status = STATUSES.IDLE;
      state.otpState.data = {};
    },
    resetSignupState(state) {
      state.signUpData.status = STATUSES.IDLE;
      state.signUpData.data = [];
    },
    setForgotData(state, action) {
      state.forgot.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loginState.status = STATUSES.LOADING;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.userData = action.payload;
        state.loginState.data = action.payload;
        state.loginState.data.token = action.payload;
        state.loginState.status = STATUSES.SUCCESS;
      })
      .addCase(login.rejected, (state, action) => {
        state.loginState.message = action.payload;
        state.loginState.status = STATUSES.ERROR;
      });
    builder
      .addCase(otpVerify.pending, (state) => {
        state.otpState.status = STATUSES.LOADING;
      })
      .addCase(otpVerify.fulfilled, (state, action) => {
        state.otpState.data = action.payload;
        state.otpState.status = STATUSES.SUCCESS;
        state.otpState.message = action.payload;
      })
      .addCase(otpVerify.rejected, (state) => {
        state.otpState.status = STATUSES.ERROR;
      });
    builder
      .addCase(register.pending, (state) => {
        state.signUpData.status = STATUSES.LOADING;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.signUpData.status = STATUSES.SUCCESS;
        state.signUpData.data = action.payload;
        state.signUpData.message = action.payload;
      })
      .addCase(register.rejected, (state) => {
        state.signUpData.status = STATUSES.ERROR;
      });
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.profileState.status = STATUSES.LOADING;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.userData = action.payload;
        state.profileState.status = STATUSES.SUCCESS;
      })
      .addCase(fetchUserData.rejected, (state) => {
        state.profileState.status = STATUSES.ERROR;
      });
    builder
      .addCase(asyncGoogle.pending, (state) => {
        state.googleState.status = STATUSES.LOADING;
      })
      .addCase(asyncGoogle.fulfilled, (state, action) => {
        state.googleState.data = action.payload;
        state.googleState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncGoogle.rejected, (state) => {
        state.googleState.status = STATUSES.ERROR;
      });
  },
});

export const {
  logOut,
  setToken,
  otpState,
  userData,
  setUserData,
  resetOtpState,
  setForgotData,
  resetSignupState,
  setLoginRejected,
} = authSlice.actions;
export default authSlice.reducer;
