import React, { useEffect } from "react";
import "./MockTestH.css";
import { NavLink } from "react-router-dom";
import { useCountdown } from "../../hooks/useCountdown";

const MockTestH = (props) => {
  const [minutes, seconds] = useCountdown(props?.info?.end_time);
  // console.log(props?.info?.id);

  useEffect(() => {}, [props]);
  return (
    <>
      <div className="mocktesth">
        <div className="mock_det">
          <NavLink to={props.nav} reloadDocument>
            <img src="../images/back_arrow.svg" alt="" />
          </NavLink>
          <div className="mocktesth_count">
            {props.info?.current_question?.index}/{props.info?.test?.no_of_qs}
          </div>
          <div className="mocktesth_time">
            <img src="../images/schedule.svg" alt="" />
            {minutes + seconds <= 0 ? (
              <div className="mocktesth_time_text">00:00</div>
            ) : (
              <div className="mocktesth_time_text">
                {minutes < 10 ? "0" + minutes : minutes}:
                {seconds < 10 ? "0" + seconds : seconds}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MockTestH;
