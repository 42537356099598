import React from "react";
import "./ForgotPass.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { forgotPass } from "../../apiCalls/auth_api";
import Button from "../../components/Button/Button";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setForgotData } from "../../redux/slices/authSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPass = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultValue = {
    email: "",
  };

  let onlyEmail = new RegExp("[a-z0-9]+@[a-z]+.com");
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .nullable()
      .email("Email is invalid")
      .required("Email is required!")
      .matches(onlyEmail, "Your Email looks like johndoe@gmail.com"),
  });

  const submitForm = (values, action) => {
    forgotPass(values.email).then((res) => {
      toast.success(res.data.message);
      // console.log(res.data.message);
      dispatch(setForgotData(res.data));
      navigate(`/changepass/${values.email}`);
    });

    action.resetForm();
  };
  return (
    <>
      <div className="forgot">
        <div className="forgot_box">
          <div className="forgot-name">
            <NavLink to="/">
              <img src="../images/RecolPark.png" alt="" />
            </NavLink>
          </div>
          <div className="forgot-para">Forgot Password</div>
          <Formik
            initialValues={defaultValue}
            validationSchema={validationSchema}
            onSubmit={submitForm}
          >
            <Form action="">
              <div className="forgot-email">
                <Field
                  type="email"
                  name="email"
                  placeholder="Email ID"
                  autoComplete="username"
                />
                <ErrorMessage name="email" />
              </div>
              <div className="forgot-btn">
                <Button
                  text="Submit"
                  type="submit"
                  fontSize="1.6rem"
                  width="11.4rem"
                  borderRadius="0.5rem"
                />
                <Button
                  text="Cancel"
                  type="submit"
                  fontSize="1.6rem"
                  width="11.4rem"
                  borderRadius="0.5rem"
                  background="#fff"
                  border="0.1rem solid #5D5A6F"
                  event={() => {
                    navigate("/signin");
                  }}
                />
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ForgotPass;
