import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./Footer.css";
import { useSelector } from "react-redux";

const Footer = () => {
  const naviagte = useNavigate();
  const { loginState } = useSelector((state) => state.auth);
  if (loginState.status == "error") {
    naviagte("/signin");
  }
  return (
    <>
      <div className="footer">
        <div className="foot">
          <ul className="foot-items">
            <li className="foot-item">
              <NavLink to="/">
                <img src="../images/RecolPark.png" alt="" />
              </NavLink>
            </li>
            <li className="foot-item">
              <Link to="/">contact@recolpark.com</Link>
            </li>
          </ul>
          <ul className="foot-items">
            <li className="foot-item">
              <Link to="/about">About</Link>
            </li>
            <li className="foot-item">
              <Link to="/terms">Terms</Link>
            </li>
            <li className="foot-item">
              <Link to="/privacy_policy">Privacy Policy</Link>
            </li>
          </ul>

          <ul className="foot-items">
            {loginState.token === null &&
            localStorage.getItem("token") === null ? null : (
              <li className="foot-item">
                <Link to="/account">My Account</Link>
              </li>
            )}
            <li className="foot-item">
              <Link to="/mentor">Mentor</Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;
