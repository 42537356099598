import React from "react";
import Base from "../../components/Base/Base";
import "./About.css";

const About = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  return (
    <>
      <div className="container">
        <Base>
          <div className="about">
            <div className="about_heading">About</div>
            <div className="about_para">
              <div className="about_title">Mission statement</div>
              <div className="about_description">
                This should briefly state the purpose of the test and who it is
                for. For example, the mission statement could be: "To provide
                students with a challenging and engaging online science test
                that helps them assess their knowledge and skills."
              </div>
            </div>
            <div className="about_para">
              <div className="about_title">Vision statement</div>
              <div className="about_description">
                This should outline your goals for the test in the future. For
                example, the vision statement could be: "To be the leading
                provider of online science tests that help students succeed in
                school and beyond."
              </div>
            </div>
            <div className="about_para">
              <div className="about_title">Values</div>
              <div className="about_description">
                These are the principles that guide your work on the test. For
                example, your values could be: accuracy, fairness, and
                innovation.
              </div>
            </div>
            <div className="about_para">
              <div className="about_title">Target market</div>
              <div className="about_description">
                Who is the test for? This could be students of a certain age
                group, grade level, or subject area.
              </div>
            </div>
            <div className="about_para">
              <div className="about_title">Brief company history</div>
              <div className="about_description">
                If you are creating the test for a company, you can include a
                brief history of the company here.
              </div>
            </div>
          </div>
        </Base>
      </div>
    </>
  );
};

export default About;
