import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import genReducer from "./slices/genSlice";
import testReducer from "./slices/testSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    gen: genReducer,
    test: testReducer,
  },
});

export default store;
