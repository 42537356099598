import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  termList,
  subjectList,
  mentorList,
  answerList,
  testStatus,
  difficultyList,
  filterTest,
  filterList,
  topicList,
  subTopicList,
} from "../../apiCalls/gen_api";

export const STATUSES = Object.freeze({
  IDLE: "idle",
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

const initialState = {
  subjectState: {
    data: [],
    status: STATUSES.IDLE,
  },
  difficultyState: {
    data: [],
    status: STATUSES.IDLE,
  },
  termState: {
    data: [],
    status: STATUSES.IDLE,
  },
  mentorState: {
    data: [],
    status: STATUSES.IDLE,
  },
  answerState: {
    data: [],
    status: STATUSES.IDLE,
  },
  testState: {
    data: [],
    status: STATUSES.IDLE,
  },
  filterState: {
    data: [],
    status: STATUSES.IDLE,
  },

  //! Free Test API

  filterTestState: {
    data: [],
    status: STATUSES.IDLE,
  },
  topicState: {
    data: [],
    status: STATUSES.IDLE,
  },
  subTopicState: {
    data: [],
    status: STATUSES.IDLE,
  },
};

export const asyncSubject = createAsyncThunk("fetchSubject", async () => {
  const res = await subjectList();
  return res.data.data;
});
export const asyncDifficulty = createAsyncThunk("fetchDifficulty", async () => {
  const res = await difficultyList();
  return res.data.data;
});

export const asyncAnswer = createAsyncThunk("fetchAnswer", async (testID) => {
  const res = await answerList(testID);
  return res.data.data;
});

export const asyncTest = createAsyncThunk("fetchTest", async () => {
  const res = await testStatus();
  return res.data.data;
});

export const asyncFilter = createAsyncThunk("filter", async () => {
  const res = await filterList();
  return res.data.data;
});
export const asyncTerm = createAsyncThunk("fetchTerm", async () => {
  const res = await termList();
  return res.data.data;
});

export const asyncMentor = createAsyncThunk("fetchMentor", async () => {
  const res = await mentorList();
  return res.data.data;
});

//! Free Test API

export const asyncFilterTest = createAsyncThunk(
  "fetchFilterTest",
  async (testID) => {
    const res = await filterTest(testID);
    return res.data;
  }
);
export const asyncTopicList = createAsyncThunk(
  "fetchTopicList",
  async (testID) => {
    const res = await topicList(testID);
    return res.data.data;
  }
);
export const asyncSubTopicList = createAsyncThunk(
  "fetchSubTopicList",
  async (testID) => {
    const res = await subTopicList(testID);
    return res.data.data;
  }
);
const genSlice = createSlice({
  name: "gen",
  initialState,
  reducers: {
    // addSubject(state, action) {
    //   state.subjectState.data = [action.payload, ...state.subjectState.data];
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncSubject.pending, (state) => {
        state.subjectState.status = STATUSES.LOADING;
      })
      .addCase(asyncSubject.fulfilled, (state, action) => {
        state.subjectState.data = action.payload;
        state.subjectState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncSubject.rejected, (state) => {
        state.subjectState.status = STATUSES.ERROR;
      });
    builder
      .addCase(asyncTerm.pending, (state) => {
        state.termState.status = STATUSES.LOADING;
      })
      .addCase(asyncTerm.fulfilled, (state, action) => {
        state.termState.data = action.payload;
        state.termState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncTerm.rejected, (state) => {
        state.termState.status = STATUSES.ERROR;
      });
    builder
      .addCase(asyncMentor.pending, (state) => {
        state.mentorState.status = STATUSES.LOADING;
      })
      .addCase(asyncMentor.fulfilled, (state, action) => {
        state.mentorState.data = action.payload;
        state.mentorState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncMentor.rejected, (state) => {
        state.mentorState.status = STATUSES.ERROR;
      });
    builder
      .addCase(asyncAnswer.pending, (state) => {
        state.answerState.status = STATUSES.LOADING;
      })
      .addCase(asyncAnswer.fulfilled, (state, action) => {
        state.answerState.data = action.payload;
        state.answerState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncAnswer.rejected, (state) => {
        state.answerState.status = STATUSES.ERROR;
      });
    builder
      .addCase(asyncTest.pending, (state) => {
        state.testState.status = STATUSES.LOADING;
      })
      .addCase(asyncTest.fulfilled, (state, action) => {
        state.testState.data = action.payload;
        state.testState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncTest.rejected, (state) => {
        state.testState.status = STATUSES.ERROR;
      });
    builder
      .addCase(asyncFilter.pending, (state) => {
        state.filterState.status = STATUSES.LOADING;
      })
      .addCase(asyncFilter.fulfilled, (state, action) => {
        state.filterState.data = action.payload;
        state.filterState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncFilter.rejected, (state) => {
        state.filterState.status = STATUSES.ERROR;
      });

    //! Free Test API

    builder
      .addCase(asyncFilterTest.pending, (state) => {
        state.filterTestState.status = STATUSES.LOADING;
      })
      .addCase(asyncFilterTest.fulfilled, (state, action) => {
        state.filterTestState.data = action.payload;
        state.filterTestState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncFilterTest.rejected, (state) => {
        state.filterTestState.status = STATUSES.ERROR;
      });
    builder
      .addCase(asyncTopicList.pending, (state) => {
        state.topicState.status = STATUSES.LOADING;
      })
      .addCase(asyncTopicList.fulfilled, (state, action) => {
        state.topicState.data = action.payload;
        state.topicState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncTopicList.rejected, (state) => {
        state.topicState.status = STATUSES.ERROR;
      });
    builder
      .addCase(asyncSubTopicList.pending, (state) => {
        state.subTopicState.status = STATUSES.LOADING;
      })
      .addCase(asyncSubTopicList.fulfilled, (state, action) => {
        state.subTopicState.data = action.payload;
        state.subTopicState.status = STATUSES.SUCCESS;
      })
      .addCase(asyncSubTopicList.rejected, (state) => {
        state.subTopicState.status = STATUSES.ERROR;
      });
  },
});

// export const { addSubject, addTerm, addMentor } = genSlice.actions;
export default genSlice.reducer;
