import React from "react";
import "./Header.css";

const Header = (props) => {
  return (
    <>
      <div className="header">
        <div className="header_det">
          <div className="header_title">{props.title}</div>
          <div className="header_txt">{props.txt}</div>
        </div>
        <div className="header_logo">
          <img src="../images/desk_man.png" alt="" />
        </div>
      </div>
    </>
  );
};

export default Header;
