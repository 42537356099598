import React from "react";
import "./TestStatus.css";
import { NavLink } from "react-router-dom";
import moment from "moment";

const TestStatus = (props) => {
  const start_time = moment(new Date(props.info.start_time).toUTCString());
  const test_time = start_time.format("MMMM Do YYYY, h:mm:ss a");
  // console.log(props.info.status);

  return (
    <>
      <div className="test_status">
        <NavLink
          className="test_status_subject"
          to={props.nav}
          onClick={props.click}
        >
          {props?.info?.test?.subject?.name}
        </NavLink>
        <div className="test_status_diff">
          Dificulty Level - {props?.info?.test?.difficulty?.name}
        </div>
        <div className="test_status_no_of_qs">
          No. of Questions - {props?.info?.test?.no_of_qs}
        </div>
        {props.info.status !== "assigned" ? (
          <div className="test_status_time">{test_time}</div>
        ) : null}
      </div>
    </>
  );
};

export default TestStatus;
