import { useEffect } from "react";
import "./Home.css";
import InteractiveSVG from "../../components/SVG/InteractiveSVG/InteractiveSVG";
import Base from "../../components/Base/Base";
import Exam from "../../components/Card/Exam/Exam";
import ChatSVG from "../../components/SVG/ChatSVG/ChatSVG";
import StudySVG from "../../components/SVG/StudySVG/StudySVG";
import LiveSVG from "../../components/SVG/LiveSVG/LiveSVG";
import { useDispatch, useSelector } from "react-redux";
import { asyncSubject } from "../../redux/slices/genSlice";

const Home = () => {
  const dispatch = useDispatch();
  const { subjectState } = useSelector((state) => state.gen);
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  useEffect(() => {
    dispatch(asyncSubject());
  }, []);

  return (
    <>
      <div className="container">
        <Base>
          <div className="home_container">
            <div className="home_studySVG">
              <StudySVG />
            </div>
            <div className="home_exam">
              {subjectState?.data?.map((pot) => {
                return <Exam name={pot?.name} key={pot?.id} element={pot} />;
              })}
            </div>
            <div className="home_liveSVG">
              <LiveSVG />
            </div>
            <div className="home_chatSVG">
              <ChatSVG />
            </div>
            <div className="home_interactiveSVG">
              <InteractiveSVG />
            </div>
          </div>
        </Base>
      </div>
    </>
  );
};

export default Home;
