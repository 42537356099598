import { useEffect, useState } from "react";
import moment from "moment";

const useCountdown = (targetDate) => {
  // console.log(targetDate);
  const countDownDate = moment(new Date(targetDate).toUTCString());
  // console.log("CountDownDate-----------", countDownDate);

  const [countDown, setCountDown] = useState(
    moment
      .duration(countDownDate.diff(moment(new Date().toUTCString())))
      .asSeconds()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log(countDown);
      setCountDown(
        moment
          .duration(countDownDate.diff(moment(new Date().toUTCString())))
          .asSeconds()
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  // console.log("countdown----------------", countDown);
  const minutes = Math.floor(countDown / 60);
  const seconds = Math.floor(countDown % 60);

  return [minutes, seconds];
};

export { useCountdown };
