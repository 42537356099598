import { API } from "../backend";
import axios from "axios";

export const termList = async () => {
  const config = {
    method: "get",
    url: `${API}page/terms-conditions/`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const mentorList = async () => {
  try {
    const res = await axios.put(`${API}mentor`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (error) {
    // console.log(error)
  }
};

export const subjectList = async () => {
  try {
    const res = await axios.get(`${API}master/subject/`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (error) {
    // console.log(error);
  }
};

export const difficultyList = async () => {
  try {
    const res = await axios.get(`${API}master/difficulty/`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (error) {
    // console.log(error)
  }
};

export const filterList = async (user) => {
  const config = {
    method: "post",
    url: `${API}live-test/`,
    data: {
      subject: user.subject,
      difficulty: user.difficulty,
      no_of_qs: user.no_of_qs,
      qtype: user.qtype,
      topic: user.topic,
    },
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const assignList = async (testID) => {
  const config = {
    method: "put",
    url: `${API}live-test/${testID}/start/`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios(config);
};

export const answerList = async (testID) => {
  const config = {
    method: "get",
    url: `${API}question/?search=${testID}&search_fields=user_test__id`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const testStatus = async (status) => {
  const config = {
    method: "get",
    url: `${API}test/?search=${status}&search_fields=status`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

//! Free Test API

export const filterTest = async (user) => {
  const config = {
    method: "post",
    url: `${API}live-test/free/`,
    data: {
      subject: user.subject,
      difficulty: user.difficulty,
      no_of_qs: user.no_of_qs,
      qtype: user.qtype,
      topic: user.topic,
      subtopic: user.subtopic,
    },
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const topicList = async (testID) => {
  try {
    const res = await axios.get(
      `${API}master/topic/?search=${testID}&search_fields=subject__id`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    // console.log(error);
  }
};

export const subTopicList = async (testID) => {
  // console.log("testID", testID);
  const arr = testID.join("&search=");

  const config = {
    method: "get",
    url: `${API}master/sub-topic/?search=${arr}&search_fields=topic__id`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};
