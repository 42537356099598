import { API } from "../backend";
import axios from "axios";

export const signIn = async (user) => {
  try {
    const res = await axios.post(
      `${API}auth/login/`,
      {
        email: user.email,
        password: user.password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const signUp = async (params) => {
  try {
    const res = await axios.post(`${API}auth/register/`, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (error) {
    // console.log(error);
  }
};

export const getUserData = async (userId) => {
  try {
    const res = await axios.get(`${API}user/${userId}/`);
    return res;
  } catch (error) {
    // console.log(error);
  }
};

export const otp = async (params) => {
  try {
    const res = await axios.post(`${API}otp/`, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (error) {
    // console.log(error);
  }
};

export const otpVerify = async (email, otp, used_for) => {
  const config = {
    method: "post",
    url: `${API}otp/verify/`,
    data: {
      email: email,
      otp: otp,
      used_for: used_for,
    },
  };
  return await axios(config);
};

export const forgotPass = async (email) => {
  const config = {
    method: "post",
    url: `${API}auth/forgot_password/`,
    data: {
      email: email,
      used_for: "fp",
    },
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const resetPass = async (email, otp, pass) => {
  const config = {
    method: "post",
    url: `${API}auth/reset_password/`,
    data: {
      email: email,
      otp: otp,
      password: pass,
    },
  };
  return await axios(config);
};

export const googleLogin = async () => {
  const config = {
    method: "post",
    url: `${API}auth/google_login/`,
  };
  return await axios(config);
};
