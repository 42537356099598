import React, { useEffect } from "react";
import Base from "../../components/Base/Base";
import "./TestSummary.css";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { submitTest } from "../../apiCalls/test_api";
import {
  asyncQList,
  asyncSummary,
  asyncSave,
  // asyncSubmit,
} from "../../redux/slices/testSlice";

const TestSummary = () => {
  const navigate = useNavigate();
  const { testID } = useParams();
  const dispatch = useDispatch();
  const { summaryState, saveState } = useSelector((state) => state.test);

  useEffect(() => {
    getSummary(testID);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const getSummary = (testID) => {
    dispatch(asyncSummary(testID));
  };

  const questionListByStatus = (testID, qstatus) => {
    dispatch(asyncQList({ testID: testID, status: qstatus }));
    navigate(`/questionlist/${testID}/${qstatus}`);
    // console.log(qlistState);
  };

  const saveHandle = () => {
    dispatch(asyncSave(testID));
  };

  // const submitHandle = (testID) => {
  //   dispatch(asyncSubmit(testID));
  //   navigate("/account");
  // };

  const submitHandle = () => {
    submitTest(testID)
      .then((response) => {
        navigate("/account");
      })
      .catch((error) => {
        navigate("/account");
        // console.log(error.response.data);
      });
  };

  return (
    <>
      <div className="container">
        <Base>
          <div className="assign_cont">
            <div className="review">
              <div
                className="column"
                onClick={() => {
                  questionListByStatus(testID, "answered");
                }}
              >
                <div className="num-box" id="num-box1">
                  <span className="num">{summaryState?.data?.answered}</span>
                </div>
                <p>Answered</p>
              </div>
              <div
                className="column"
                onClick={() => {
                  questionListByStatus(testID, "not_answered");
                }}
              >
                <div className="num-box" id="num-box2">
                  <span className="num">
                    {summaryState?.data?.not_answered}
                  </span>
                </div>
                <p>Not Answered</p>
              </div>
              <div
                className="column"
                onClick={() => {
                  questionListByStatus(testID, "marked_for_review");
                }}
              >
                <div className="num-box" id="num-box3">
                  <span className="num">{summaryState?.data?.review}</span>
                </div>
                <p>Marked For Review</p>
              </div>
              <div
                className="column"
                onClick={() => {
                  questionListByStatus(testID, "skipped");
                }}
              >
                <div className="num-box" id="num-box4">
                  <span className="num">{summaryState?.data?.skipped}</span>
                </div>
                <p>Skipped</p>
              </div>

              <div
                className="column"
                onClick={() => {
                  questionListByStatus(testID, "answered_marked_for_review");
                }}
              >
                <div className="num-box" id="num-box5">
                  <span className="num">
                    {summaryState?.data?.answered_review}
                  </span>
                </div>
                <p>Answer & Mark for Review</p>
              </div>
            </div>
            <div className="assignM_btn">
              {saveState.data.status ||
              summaryState.data.status === "saved_for_later" ? null : (
                <Button
                  width="30rem"
                  height="3rem"
                  background="#404DBF"
                  border="1px solid #404DBF"
                  text="Save for later"
                  color="#ffffff"
                  borderRadius="0.5rem"
                  event={() => {
                    saveHandle();
                  }}
                />
              )}
              <Button
                width="30rem"
                height="3rem"
                background="#404DBF"
                border="1px solid #404DBF"
                text="Submit"
                color="#ffffff"
                borderRadius="0.5rem"
                event={() => {
                  submitHandle();
                }}
              />
            </div>
          </div>
        </Base>
      </div>
    </>
  );
};

export default TestSummary;
