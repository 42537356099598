import React from "react";
import "./StudySVG.css";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const StudySVG = () => {
  const navigate = useNavigate();
  const { loginState } = useSelector((state) => state.auth);
  return (
    <>
      <div className="studySVG">
        <img src="../images/study.png" alt="" />
        <div className="studySVG_det">
          <div className="studySVG_text">Our mentor help you instantly</div>
          <div className="studySVG_title">Improve your study</div>
          <div className="studySVG_det_text">
            We are available for Engneering Entrance Exam
          </div>
        </div>
        <div className="studySVG_btn">
          <Button
            text="Mentors"
            background="#B0E5E9"
            width="13.2rem"
            height="4rem"
            event={() => {
              navigate("/mentor");
            }}
          />
          {loginState.token === null &&
          localStorage.getItem("token") === null ? (
            <Button
              text="Filters"
              background="#FFD593"
              width="13.2rem"
              height="4rem"
              border="0.2rem solid #FFD593"
              event={() => {
                navigate("/filters", {
                  state: { id: 1 },
                });
              }}
            />
          ) : (
            <Button
              text="Filters"
              background="#FFD593"
              width="13.2rem"
              height="4rem"
              border="0.2rem solid #FFD593"
              event={() => {
                navigate("/filter", {
                  state: { id: 1 },
                });
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StudySVG;
