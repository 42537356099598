import React, { useState, useEffect } from "react";
import "./App.css";
import axios from "axios";
import Routings from "./Route/Routing";
import { HashRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Loader from "./components/SVG/Loader/Loader";
import { GoogleOAuthProvider } from "@react-oauth/google";

// !Add a request interceptor
axios.interceptors.request.use(
  (request) => {
    request.headers.Authorization = localStorage.getItem("userToken")
      ? `Bearer ${localStorage.getItem("userToken")}`
      : null;
    // console.log(request);
    return request;
  },
  (error) => {
    // console.log("error-------", error?.response);
    return Promise.reject(error);
  }
);

// !Add a response interceptor
axios.interceptors.response.use(
  (res) => {
    if (res?.status == 201 || res?.status == 200) {
      toast.success(res?.data?.success?.message);
    }
    return res;
  },
  (error) => {
    if (error?.response?.status == 400) {
      if ("non_field_errors" in error?.response?.data) {
        toast.error(error?.response?.data.non_field_errors[0]);
      } else {
        toast.error(error?.response?.data[0]);
      }
    }
    if (error?.response?.status == 401 || error?.response?.status == 403) {
      localStorage.removeItem("userToken");
      toast.error("You are not login");
    }
    if (error?.response?.status == 500) {
      toast.error("Internal Server Error!");
    }
    return Promise.reject(error);
  }
);

const App = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // !Add a request interceptor
    axios.interceptors.request.use(
      async (request) => {
        request.headers.Authorization = localStorage.getItem("userToken")
          ? `Bearer ${localStorage.getItem("userToken")}`
          : null;
        request.headers["Content-Type"] = "application/json";
        // console.log(request.data);
        setLoading(true);
        return request;
      },
      function (error) {
        setLoading(false);
        // console.log('App_error', error?.response.data);
        return Promise.reject(error);
      }
    );

    // !Add a response interceptor
    axios.interceptors.response.use(
      function (config) {
        setLoading(false);
        // console.log("App_Response", config.data);
        return config;
      },
      function (error) {
        setLoading(false);
        // console.log("App_error", error?.response.data);
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <GoogleOAuthProvider clientId="443111760380-n94bfmp11abvgnv2v6e4drv9aiki43sq.apps.googleusercontent.com">
      <HashRouter>
        <Loader show={loading} />
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routings />
      </HashRouter>
    </GoogleOAuthProvider>
  );
};

export default App;
