import React, { useEffect } from "react";
import * as yup from "yup";
import "./Signup.css";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { NavLink } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  STATUSES,
  setUserData,
  otpVerify,
  resetOtpState,
} from "../../redux/slices/authSlice";

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { otpState } = useSelector((state) => state.auth);
  const defaultValue = {
    name: "",
    email: "",
    password: "",
  };

  const onlyText = /^[a-zA-Z ]*$/;
  let onlyEmail = new RegExp("[a-z0-9]+@[a-z]+.com");
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .matches(onlyText, "Name must be text")
      .nullable()
      .required("Name is required!")
      .max(30),
    email: yup
      .string()
      .matches(onlyEmail, "Your Email looks like johndoe@gmail.com")
      .email("Email is invalid")
      .required("Email is required!"),
    password: yup
      .string()
      .nullable()
      .trim()
      .min(8, "Password must be at least 8 characters")
      .max(20, "Password must be at most 20 characters")
      .required("Password is required!"),
  });

  const submitForm = (values, action) => {
    // console.log(values);
    dispatch(setUserData(values));
    dispatch(otpVerify({ email: values.email, used_for: "reg" }));
    action.resetForm();
  };

  useEffect(() => {
    if (otpState.status === STATUSES.SUCCESS) {
      // console.log(otpState?.data);
      dispatch(resetOtpState());

      if (otpState?.data?.status == 201) {
        navigate("/otp");
      }
    }
    if (otpState.status === STATUSES.ERROR) {
      // console.log(otpState?.data);
      dispatch(resetOtpState());
    }
  }, [otpState]);

  return (
    <>
      <div className="signup">
        <div className="signup-box">
          <div className="signup-name">
            <NavLink to="/">
              <img src="../images/RecolPark.png" alt="" />
            </NavLink>
          </div>
          <div className="signup-para">Create you account here</div>
          <Formik
            initialValues={defaultValue}
            validationSchema={validationSchema}
            onSubmit={submitForm}
          >
            <Form action="" className="signup-form">
              <div className="signup-email">
                <Field
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  autoComplete="username"
                />
                <ErrorMessage name="name" />
              </div>
              <div className="signup-email">
                <Field
                  type="email"
                  name="email"
                  placeholder="Email ID"
                  autoComplete="username"
                />
                <ErrorMessage name="email" />
              </div>
              <div className="signup-email">
                <Field
                  type="password"
                  name="password"
                  placeholder="Password"
                  autoComplete="current-password"
                />
                <ErrorMessage name="password" />
              </div>
              <div className="signup-btn">
                <Button
                  text="Register"
                  type="submit"
                  fontSize="1.6rem"
                  width="29.8rem"
                  borderRadius="1rem"
                />
              </div>
            </Form>
          </Formik>
          <div className="signup-account">
            <p>Already have an account?</p>
            <div className="signin-link">
              <NavLink to="/signin">Sign In</NavLink>
            </div>
          </div>
          <div className="signup-back">
          <NavLink to="/">Back</NavLink>
        </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
