import React from "react";
import "./Exam.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Exam = (props) => {
  const { loginState } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const examHandler = () => {
    if (loginState.token === null && localStorage.getItem("token") === null) {
      navigate("/filters", {
        state: { name: props.name, id: props.element.id },
      });
    } else {
      navigate("/filter", {
        state: { name: props.name, id: props.element.id },
      });
    }
  };
  return (
    <>
      <div className="exam" onClick={() => examHandler()}>
        <div className="exam_title">{props.name}</div>
        <div className="exam_test">On-Demand Test Available</div>
      </div>
    </>
  );
};

export default Exam;
