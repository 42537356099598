import React, { useEffect } from "react";
import Base from "../../components/Base/Base";
// import parse from "html-react-parser";
import "./AnswerSheet.css";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import FreeAnswer from "../../components/Card/FreeAnswer/FreeAnswer";
import { useDispatch, useSelector } from "react-redux";
import { asyncAnswer } from "../../redux/slices/genSlice";

const AnswerSheet = () => {
  const dispatch = useDispatch();
  const { testID, no_of_qs, total_marks } = useParams();
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  const { answerState } = useSelector((state) => state.gen);

  useEffect(() => {
    dispatch(asyncAnswer(testID));
  }, []);

  return (
    <>
      <div className="container">
        <Base>
          <div className="answer_sheet">
            <div className="answer_sheet_mockh">
              <div className="answer_test_header">
                <div className="answer_test_header_det">
                  <NavLink to="/account" reloadDocument>
                    <img src="../images/back_arrow.svg" alt="" />
                  </NavLink>
                  <div className="answer_test_header_count">
                    {no_of_qs} Questions
                  </div>
                  <div className="answer_test_header_marks">
                    {total_marks} Marks
                  </div>
                </div>
              </div>
            </div>
            <div className="answer_sheet_test">
              {answerState?.data?.map((item, index) => {
                return <FreeAnswer index={index} info={item} />;
              })}
            </div>
          </div>
        </Base>
      </div>
    </>
  );
};

export default AnswerSheet;
