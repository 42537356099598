import React, { useEffect } from "react";
import "./QuestionList.css";
import Base from "../../components/Base/Base";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { asyncQList } from "../../redux/slices/testSlice";

const QuestionList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { testID, qstatus } = useParams();
  const { qlistState } = useSelector((state) => state.test);

  useEffect(() => {
    dispatch(asyncQList({ testID: testID, status: qstatus }));
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  if (qlistState?.data?.questions?.length == 0) {
    navigate(`/summary/${testID}`);
  }
  // console.log("qlistState", qlistState?.data?.questions?.length);

  return (
    <>
      <div className="container">
        <Base>
          <div className="mock_cont">
            <NavLink
              to={{
                pathname: `/summary/${testID}`,
                state: { testID: testID },
              }}
            >
              <img src="../images/back_arrow.svg" alt="" />
            </NavLink>
            <div className="question">
              <div className="row">
                {qlistState.data.questions?.map((item) => {
                  return (
                    <div
                      className="num-box"
                      element={item}
                      key={item.id}
                      id="num-box1"
                      onClick={() => {
                        navigate(`/update/${testID}/${item}/${qstatus}`, {
                          data: {
                            testID: testID,
                            status: qstatus,
                            index: qlistState?.data?.current_question?.index,
                          },
                        });
                      }}
                    >
                      <span className="num">{item}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Base>
      </div>
    </>
  );
};

export default QuestionList;
