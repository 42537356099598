import React, { useEffect } from "react";
import * as yup from "yup";
import "./Signin.css";
// import jwt_decode from "jwt-decode";
import { useNavigate, NavLink } from "react-router-dom";
import Button from "../../components/Button/Button";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES, login } from "../../redux/slices/authSlice";
// import { GoogleLogin } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const SignIn = () => {
  // const [user, setUser] = useState({});
  const navigate = useNavigate();
  const { loginState } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const defaultValue = {
    email: "",
    password: "",
  };

  let onlyEmail = new RegExp("[a-z0-9]+@[a-z]+.com");
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .nullable()
      .email("Email is invalid")
      .matches(onlyEmail, "Your Email looks like johndoe@gmail.com")
      .required("Email is required!"),
    password: yup
      .string()
      .nullable()
      .trim()
      .required("Password is required!")
      .min(8, "Password must be at least 8 characters")
      .max(20, "Password must be at most 20 characters"),
  });

  useEffect(() => {
    if (loginState.status === STATUSES.SUCCESS && loginState.token !== "") {
      // console.log(loginState.data.succes);
      navigate("/");
    } else if (loginState.status === STATUSES.ERROR) {
      navigate("/signin");
      // toast.error(loginState.message[0]);
    }
  }, [loginState]);

  // console.log("user", user);

  const handleSubmit = (values, action) => {
    dispatch(login(values));
    action.resetForm();
  };

  // console.log("loginState", loginState);

  return (
    <>
      <div className="signin">
        <div className="signin-box">
          <div className="signin-name">
            <NavLink to="/">
              <img src="../images/RecolPark.png" alt="" />
            </NavLink>
          </div>
          <div className="signin-para">Welcome back to your account</div>
          <Formik
            initialValues={defaultValue}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form action="" className="signin-form">
              <div className="signin-email">
                <Field
                  type="email"
                  name="email"
                  placeholder="Email"
                  autoComplete="username"
                />
                <ErrorMessage name="email" />
              </div>
              <div className="signin-email">
                <Field
                  type="password"
                  name="password"
                  placeholder="Password"
                  autoComplete="current-password"
                />
                <ErrorMessage name="password" />
              </div>
              <div className="signin-forget">
                <NavLink to="/forgotpass">Forgot Your Password?</NavLink>
              </div>
              <div className="signin-btn">
                <Button
                  text="Sign In"
                  type="submit"
                  fontSize="1.6rem"
                  width="29.8rem"
                  borderRadius="1rem"
                />
              </div>
            </Form>
          </Formik>
          <div className="signin-account">
            <p>Don't have an account?</p>
            <div className="signup-link">
              <NavLink to="/signup">Sign Up</NavLink>
            </div>
          </div>
          <div className="signup-back">
            <NavLink to="/">Back</NavLink>
          </div>
          <div className="google_login">
            {/*
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                var userObject = jwt_decode(credentialResponse.credential);
                setUser(userObject);
                localStorage.setItem(
                  "token",
                  credentialResponse.credential || ""
                ); // set token in local storage for future use of the app (for now it's
                navigate("/");
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            />
            ;
            */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
