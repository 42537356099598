import React from "react";
import "./InteractiveSVG.css";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";

const InteractiveSVG = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="interactiveSVG">
        <div className="interactiveSVG_img">
          <img src="../images/whiteboard.png" alt="" />
        </div>
        <div className="interactiveSVG_det">
          <div className="interactiveSVG_det_title">
            Interactive Live Classes
          </div>
          <div className="interactiveSVG_det_text">
            Learn and chat directly with expert faculty in an interactive
            classroom
          </div>
          <div className="interactiveSVG_det_btn">
            <Button
              text="Schedule Now"
              event={() => {
                navigate("/mentor");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InteractiveSVG;
