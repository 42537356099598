import { API } from "../backend";
import axios from "axios";

export const nextQS = async (id, test) => {
  const config = {
    method: "put",
    url: `${API}live-test/${id}/next/`,
    data: {
      question: test.question,
      option: test.option,
    },
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const previousQS = async (id, test) => {
  const config = {
    method: "get",
    url: `${API}live-test/${id}/previous/`,
    data: {
      question: test.question,
      option: test.option,
    },
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const skipQS = async (id, test) => {
  const config = {
    method: "put",
    url: `${API}live-test/${id}/skip/`,
    data: {
      question: test.question,
      option: test.option,
    },
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const reviewQS = async (id, test) => {
  const config = {
    method: "put",
    url: `${API}live-test/${id}/review/`,
    data: {
      question: test.question,
    },
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const answer_reviewQS = async (id, test) => {
  const config = {
    method: "put",
    url: `${API}live-test/${id}/review/`,
    data: {
      question: test.question,
      option: test.option,
    },
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const currentQS = async (id) => {
  const config = {
    method: "get",
    url: `${API}live-test/${id}/current/`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  // console.log(id);
  return await axios(config);
};

export const summaryT = async (id) => {
  const config = {
    method: "get",
    url: `${API}live-test/${id}/summary/`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const getByIndex = async (id, index) => {
  const config = {
    method: "get",
    url: `${API}live-test/${id}/getByIndex/?index=${index}`,
    // data: {
    //   index: index.index,
    // },
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const submitTest = async (id) => {
  const config = {
    method: "put",
    url: `${API}live-test/${id}/submit/`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const saveTest = async (id) => {
  const config = {
    method: "put",
    url: `${API}live-test/${id}/save/`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const questionListByStatus = async (id, status) => {
  const config = {
    method: "get",
    url: `${API}live-test/${id}/question/?status=${status}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};

export const saveQuestion = async (id, test) => {
  const config = {
    method: "put",
    url: `${API}live-test/${id}/saveQuestion/`,
    data: {
      question: test.question,
      option: test.option,
    },
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios(config);
};
