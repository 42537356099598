import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Filter.css";
import Base from "../../components/Base/Base";
import Button from "../../components/Button/Button";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { filterList } from "../../apiCalls/gen_api";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncSubject,
  asyncTopicList,
  asyncSubTopicList,
} from "../../redux/slices/genSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import { Multiselect } from "multiselect-react-dropdown";

const Filter = () => {
  const dispatch = useDispatch();
  const { subjectState, topicState, subTopicState } = useSelector(
    (state) => state.gen
  );
  const [topics, setTopics] = useState([]);
  const [subTopics, setSubTopics] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const selected_subject = location.state?.id;
  // window.scrollTo({
  //   top: 0,
  //   left: 0,
  //   behavior: "smooth",
  // });

  const validationSchema = yup.object().shape({
    no_of_qs: yup
      .number("No. of Question should be Integer")
      .integer("No. of Question should be Integer")
      .min(10, "No. of Question should be between the range 10 to 100")
      .max(100, "No. of Question should be between the range 10 to 100"),
  });

  const defaultFormValues = {
    subject: `${String(selected_subject)}`,
    difficulty: "1",
    no_of_qs: "10",
    qtype: "1",
  };

  const multiselectRef = useRef();

  const topicHandler = (e, params) => {
    dispatch(asyncTopicList(params));
    multiselectRef?.current?.resetSelectedValues();
  };
  useEffect(() => {
    dispatch(asyncSubject());
    dispatch(asyncTopicList(selected_subject));
    // topicHandler();
  }, []);

  let topic = [];
  topics.map((item) => {
    topic.push(item.id);
  });

  let subTopic = [];
  subTopics.map((item) => {
    subTopic.push(item.id);
  });

  const subTopicHandler = (e) => {
    let topic = [];
    e.map((item) => {
      topic.push(item.id);
    });
    dispatch(asyncSubTopicList(topic));
  };

  const handleSubmit = (values) => {
    if (values.subject == "undefined") {
      toast.error("Subject is Not Selected");
    }
    if (values.no_of_qs == "") {
      toast.error("No of Question is Not Selected");
    }
    if (topic == "") {
      toast.error("Topic is Not Selected");
    }
    // if (subTopic == "") {
    //   toast.error("Sub-Topic is Not Selected");
    // }
    console.log("subTopic", subTopic);
    const { subject, difficulty, no_of_qs, qtype } = values;
    filterList({
      subject,
      difficulty,
      no_of_qs,
      qtype,
      topic: topic,
      subtopic: subTopic,
    })
      .then((response) => {
        navigate("/mock-test/", { state: { data: response.data } });
      })
      .catch((error) => {
        // console.log(error.response.data.detail);
      });
  };
  return (
    <>
      <div className="container">
        <Base>
          <div className="filter_cont">
            <Formik
              initialValues={defaultFormValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values }) => (
                <Form>
                  <div className="subject">
                    <div className="subject_title">Subject</div>
                    <div
                      className="subject_option"
                      role="group"
                      aria-labelledby="my-radio-group"
                    >
                      {subjectState.data.map((item) => {
                        return (
                          <div className="subject-select" key={item.id}>
                            <label>
                              <Field
                                type="radio"
                                name="subject"
                                value={String(item.id)}
                                onClick={(e) => {
                                  topicHandler(e, item.id);
                                }}
                              />
                              <span>{item.name}</span>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="subject">
                    <div className="subject_title">Topic</div>
                    <div className="subject_option2">
                      <Multiselect
                        options={topicState.data}
                        selectedValues={topics}
                        displayValue="name"
                        ref={multiselectRef}
                        onSelect={(e) => {
                          setTopics(e);
                          subTopicHandler();
                        }}
                        onRemove={(e) => {
                          setTopics(topics.filter((val) => val !== e));
                        }}
                      />
                    </div>
                  </div>
                  <div className="subject">
                    <div className="subject_title">Sub Topic</div>
                    <div className="subject_option2">
                      <Multiselect
                        options={subTopicState.data}
                        selectedValues={subTopics}
                        displayValue="name"
                        onSelect={(e) => {
                          setSubTopics(e);
                        }}
                        onRemove={(e) => {
                          setSubTopics(subTopics.filter((val) => val !== e));
                        }}
                      />
                    </div>
                  </div>
                  <div className="subject">
                    <div className="subject_title">Difficulty Level</div>
                    <div className="subject_option">
                      <div className="subject-select">
                        <label>
                          <Field type="radio" name="difficulty" value="1" />
                          <span>Basic</span>
                        </label>
                      </div>
                      <div className="subject-select">
                        <label>
                          <Field type="radio" name="difficulty" value="2" />
                          <span>Medium</span>
                        </label>
                      </div>
                      <div className="subject-select">
                        <label>
                          <Field type="radio" name="difficulty" value="3" />
                          <span>Advanced</span>
                        </label>
                      </div>
                      <div className="subject-select">
                        <label>
                          <Field type="radio" name="difficulty" value="4" />
                          <span>Mix</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="quest">
                    <div className="quest_title">No. of Questions</div>
                    <div className="quest_option">
                      <div className="quest_select">
                        <label>
                          <Field type="radio" name="no_of_qs" value="10" />
                          <span>10</span>
                        </label>
                      </div>
                      <div className="quest_select">
                        <label>
                          <Field type="radio" name="no_of_qs" value="15" />
                          <span>15</span>
                        </label>
                      </div>
                      <div className="quest_select">
                        <label>
                          <Field type="radio" name="no_of_qs" value="25" />
                          <span>25</span>
                        </label>
                      </div>
                      <div className="quest_select">
                        <label>
                          <Field type="radio" name="no_of_qs" value="50" />
                          <span>50</span>
                        </label>
                      </div>
                      <div className="quest_select">
                        <label>
                          <Field type="radio" name="no_of_qs" value="75" />
                          <span>75</span>
                        </label>
                      </div>
                      <div className="quest_select">
                        <label>
                          <Field type="radio" name="no_of_qs" value="100" />
                          <span>100</span>
                        </label>
                      </div>
                    </div>
                    <div className="quest_or">Or</div>
                    <div className="quest_option">
                      <div className="quest_select_in">
                        <Field
                          type="text"
                          name="no_of_qs"
                          placeholder="Enter your own choice"
                        />
                      </div>
                      <ErrorMessage
                        name="no_of_qs"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="filter_btn">
                    <Button type="submit" text="Apply" onClick={handleSubmit} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Base>
      </div>
    </>
  );
};

export default Filter;
