import React from "react";
import Base from "../../components/Base/Base";
import "./AnswerSheetNoLog.css";
import { NavLink } from "react-router-dom";
import FreeAnswer from "../../components/Card/FreeAnswer/FreeAnswer";

const AnserSheetNoLog = () => {
  const data = JSON.parse(localStorage.getItem("mockData"));

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  // console.log("Answer_Sheet", data);

  return (
    <>
      <div className="container">
        <Base>
          <div className="answer_sheet">
            <div className="answer_sheet_mockh">
              <div className="answer_test_header">
                <div className="answer_test_header_det">
                  <NavLink to="/">
                    <img src="../images/back_arrow.svg" alt="" />
                  </NavLink>
                  <div className="answer_test_header_count">
                    {data?.no_of_qs} Questions
                  </div>
                  <div className="answer_test_header_marks">
                  {data?.score}/{data?.total_marks} Marks
                  </div>
                </div>
              </div>
            </div>
            <div className="answer_sheet_test">
              {data?.questions?.map((item,index) => {
                return <FreeAnswer info={item} index={index}/>;
              })}
            </div>
          </div>
        </Base>
      </div>
    </>
  );
};

export default AnserSheetNoLog;
