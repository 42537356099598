import React, { useRef } from "react";
import "./MentorDetails.css";
import Base from "../../components/Base/Base";
import MentorC from "../../components/Card/MentorC/MentorC";
import Button from "../../components/Button/Button";
import TeacherRatings from "../../components/Card/TeacherRatings/TeacherRatings";
// import { useParams } from "react-router-dom";

const MentorDetails = (props) => {
  // const {data} = useParams
  const mentabou = useRef();
  const mentdetail = useRef();
  const mentrate = useRef();

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  const scrollHandler = (elmRef) => {
    window.scrollTo({ top: elmRef.current.offsetTop, behavior: "smooth" });
    // console.log(elmRef.current);
  };
  return (
    <>
      <div className="container">
        <Base>
          <div className="md_cont">
            <div className="md_header">
              <MentorC />
            </div>
            <div className="md_btn">
              <div className="md_btn_s">
                <Button
                  width="31.1rem"
                  background="#FF6652"
                  border="2px solid #FF6652"
                  text="About"
                  color="#ffffff"
                  fontWeight="500"
                  event={() => scrollHandler(mentabou)}
                />
              </div>
              <div className="md_btn_s">
                <Button
                  width="31.1rem"
                  background="#fff"
                  border="1px solid #FF6652"
                  text="Details"
                  fontWeight="500"
                  event={() => scrollHandler(mentdetail)}
                />
              </div>
              <div className="md_btn_s">
                <Button
                  width="31.1rem"
                  background="#FFFF"
                  border="1px solid #FF6652"
                  text="Review"
                  fontWeight="500"
                  event={() => scrollHandler(mentrate)}
                />
              </div>
            </div>
            <div className="md_det">
              <div className="md_about">
                <div className="md_about_title" ref={mentabou}>
                  About
                </div>
                <div className="md_about_txt">
                  {props.about}
                  Minimum qualification required to be appointed as a
                  pre-primary or primary school teacher in any Government or
                  Private School. To move the level up to secondary and
                  professor positions, one must complete one or more of the
                  courses which are mentioned
                </div>
              </div>
              <div className="md_about">
                <div className="md_about_title">Qualification</div>
                <div className="md_about_txt">
                  {props.qualification}
                  Minimum qualification required to be appointed as a
                  pre-primary or primary school teacher in any Government or
                  Private School. To move the level up to secondary and
                  professor positions, one must complete one or more of the
                  courses which are mentioned below: Bachelor of Education.
                </div>
              </div>
              <div className="md_about">
                <div className="md_about_title">Experience</div>
                <div className="md_about_txt">
                  {props.experience}
                  Teaching experience is the culmination of skills, exposure or
                  training acquired over time that enables you to perform an
                  existing job better or prepare you for a teaching position.
                  You acquire teaching experience through different avenues,
                  some less common than others.
                </div>
              </div>
              <div className="md_about">
                <div className="md_about_title" ref={mentdetail}>
                  Details
                </div>
                <div className="md_about_txt">
                  {props.details}
                  Minimum qualification required to be appointed as a
                  pre-primary or primary school teacher in any Government or
                  Private School. To move the level up to secondary and
                  professor positions, one must complete one or more of the
                  courses which are mentioned
                </div>
              </div>
              <div className="md_about">
                <div className="md_about_title" ref={mentrate}>
                  Rating & Reviews
                </div>
                <TeacherRatings />
                <TeacherRatings />
                <TeacherRatings />
              </div>
            </div>
          </div>
        </Base>
      </div>
    </>
  );
};

export default MentorDetails;
