import React, { useState, useEffect } from "react";
import Base from "../../components/Base/Base";
import Header from "../../components/Header/Header";
import "./Account.css";
import TestStatus from "../../components/Card/TestStatus/TestStatus";
import { testStatus } from "../../apiCalls/gen_api";
import { useNavigate } from "react-router-dom";
import { assignList } from "../../apiCalls/gen_api";
import { RiArrowUpSLine } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";

const Account = () => {
  const [inProgress, setInProgress] = useState([]);
  const [savedForLater, setSavedForLater] = useState([]);
  const [submitted, setSubmitted] = useState([]);
  const [assigned, setAssigned] = useState([]);
  const navigate = useNavigate();
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  const accountstatu = (status) => {
    testStatus(status)
      .then((response) => {
        if (status === "assigned") {
          setAssigned(response.data);
        }
        if (status === "in_progress") {
          setInProgress(response.data);
        }
        if (status === "saved_for_later") {
          setSavedForLater(response.data);
        }
        if (status === "submitted") {
          setSubmitted(response.data);
        }
      })
      .catch((error) => {
        // console.log(error.response);
        if (error.response.status === 403) {
          navigate("/signin");
        }
      });
  };

  const handleSubmit = (event, param) => {
    // console.log("event", event);
    // console.log("param", param);
    // console.log("param", param?.test?.id);
    const testID = param?.id;
    assignList(testID)
      .then((response) => {
        // console.log(response.data);
        navigate("/mock-test", { state: { data: response.data } });
      })
      .catch((error) => {
        // console.log(error.response.data);
        if (error.response.status === 403) {
          navigate("/signin");
        }
      });
  };

  // console.log(assigned);
  useEffect(() => {
    accountstatu("assigned");
    accountstatu("in_progress");
    accountstatu("saved_for_later");
    accountstatu("submitted");
  }, []);

  // console.log(assigned.data.no_of_qs);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  return (
    <>
      <div className="container">
        <Base>
          <div className="account_cont">
            <div className="account_header">
              <Header title="My Account" />
            </div>
            <div className="acc_asign">
              <div className="acc_asign_head" onClick={() => setShow1(!show1)}>
                <div className="acc_asign_header">Assigned</div>
                {show1 === true ? <RiArrowUpSLine /> : <MdKeyboardArrowDown />}
              </div>
              {show1 && (
                <div className="acc_asign_mentor">
                  {assigned?.data?.length === 0 ? (
                    <div className="no-result">
                      <div className="result-text">No Data</div>
                    </div>
                  ) : (
                    assigned?.data?.sort()?.map((item) => {
                      // console.log(item?.no_of_qs);
                      return (
                        <TestStatus
                          key={item.id}
                          element={item}
                          info={item}
                          click={(event) => handleSubmit(event, item)}
                        />
                      );
                    })
                  )}
                </div>
              )}
            </div>
            <div className="acc_asign">
              <div className="acc_asign_head" onClick={() => setShow2(!show2)}>
                <div className="acc_asign_header">In Progress</div>
                {show2 === true ? <RiArrowUpSLine /> : <MdKeyboardArrowDown />}
              </div>
              {show2 && (
                <div className="acc_asign_mentor">
                  {inProgress?.data?.length === 0 ? (
                    <div className="no-result">
                      <div className="result-text">No Data</div>
                    </div>
                  ) : (
                    inProgress?.data?.map((item) => {
                      return (
                        <TestStatus
                          key={item.id}
                          info={item}
                          nav={{
                            pathname: `/summary/${item?.id}`,
                            state: { testID: item?.id },
                          }}
                        />
                      );
                    })
                  )}
                </div>
              )}
            </div>
            <div className="acc_asign">
              <div className="acc_asign_head" onClick={() => setShow3(!show3)}>
                <div className="acc_asign_header">Saved for Later</div>
                {show3 === true ? <RiArrowUpSLine /> : <MdKeyboardArrowDown />}
              </div>
              {show3 && (
                <div className="acc_asign_mentor">
                  {savedForLater?.data?.length === 0 ? (
                    <div className="no-result">
                      <div className="result-text">No Data</div>
                    </div>
                  ) : (
                    savedForLater?.data?.map((item) => {
                      return (
                        <TestStatus
                          key={item.id}
                          info={item}
                          nav={{
                            pathname: `/summary/${item.id}`,
                            state: { testID: item?.id },
                          }}
                        />
                      );
                    })
                  )}
                </div>
              )}
            </div>
            <div className="acc_asign">
              <div className="acc_asign_head" onClick={() => setShow4(!show4)}>
                <div className="acc_asign_header">Submitted</div>
                {show4 === true ? <RiArrowUpSLine /> : <MdKeyboardArrowDown />}
              </div>
              {show4 && (
                <div className="acc_asign_mentor">
                  {submitted?.data?.length === 0 ? (
                    <div className="no-result">
                      <div className="result-text">No Data</div>
                    </div>
                  ) : (
                    submitted?.data?.map((item) => {
                      return (
                        <TestStatus
                          key={item.id}
                          element={item}
                          info={item}
                          nav={{
                            pathname: `/answer/${item.id}/${item.no_of_qs}/${item.total_marks}`,
                            state: {
                              testID: item.id,
                              no_of_qs: item?.no_of_qs,
                              total_marks: item?.total_marks,
                            },
                          }}
                        />
                      );
                    })
                  )}
                </div>
              )}
            </div>
          </div>
        </Base>
      </div>
    </>
  );
};

export default Account;
