import React from "react";
import Base from "../../components/Base/Base";
import "./Terms.css";

const Terms = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  return (
    <>
      <div className="container">
        <Base>
          <div className="terms">
            <div className="terms_heading">Terms</div>
            <div className="terms_para">
              <div className="terms_title">Welcome to RecolPark!</div>
              <div className="terms_description">
                These terms and conditions outline the rules and regulations for
                the use of RecolPark's Website, located at RecolPark-Home
                (recolpark.com)
                <br /> <br /> By accessing this website we assume you accept
                these terms and conditions. Do not continue to use RecolPark if
                you do not agree to take all of the terms and conditions stated
                on this page.
                <br /> <br />
                The following terminology applies to these Terms and Conditions,
                Privacy Statement and Disclaimer Notice and all Agreements:
                "Client", "You" and "Your" refers to you, the person log on this
                website and compliant to the Company’s terms and conditions.
                "The Company", "Ourselves", "We", "Our" and "Us", refers to our
                Company. "Party", "Parties", or "Us", refers to both the Client
                and ourselves. All terms refer to the offer, acceptance and
                consideration of payment necessary to undertake the process of
                our assistance to the Client in the most appropriate manner for
                the express purpose of meeting the Client’s needs in respect of
                provision of the Company’s stated services, in accordance with
                and subject to, prevailing law of Netherlands. Any use of the
                above terminology or other words in the singular, plural,
                capitalization and/or he/she or they, are taken as
                interchangeable and therefore as referring to same.
              </div>
            </div>
            <div className="terms_para">
              <div className="terms_title">Cookies</div>
              <div className="terms_description">
                Cookies We employ the use of cookies. By accessing RecolPark,
                you agreed to use cookies in agreement with the RecolPark's
                Privacy Policy. Most interactive websites use cookies to let us
                retrieve the user’s details for each visit. Cookies are used by
                our website to enable the functionality of certain areas to make
                it easier for people visiting our website. Some of our
                affiliate/advertising partners may also use cookies.
              </div>
            </div>
            <div className="terms_para">
              <div className="terms_title">iFrames</div>
              <div className="terms_description">
                Without prior approval and written permission, you may not
                create frames around our Webpages that alter in any way the
                visual presentation or appearance of our Website.
              </div>
            </div>

            <div className="terms_para">
              <div className="terms_title">Content Liability</div>
              <div className="terms_description">
                We shall not be hold responsible for any content that appears on
                your Website. You agree to protect and defend us against all
                claims that is rising on your Website. No link(s) should appear
                on any Website that may be interpreted as libelous, obscene or
                criminal, or which infringes, otherwise violates, or advocates
                the infringement or other violation of, any third party rights.
              </div>
            </div>
            <div className="terms_para">
              <div className="terms_title">Your Privacy</div>
              <div className="terms_description">
                Please read Privacy Policy
              </div>
            </div>
            <div className="terms_para">
              <div className="terms_title">Reservation of Rights</div>
              <div className="terms_description">
                We reserve the right to request that you remove all links or any
                particular link to our Website. You approve to immediately
                remove all links to our Website upon request. We also reserve
                the right to amen these terms and conditions and it’s linking
                policy at any time. By continuously linking to our Website, you
                agree to be bound to and follow these linking terms and
                conditions.
              </div>
            </div>
            <div className="terms_para">
              <div className="terms_title">
                Removal of links from our website
              </div>
              <div className="terms_description">
                If you find any link on our Website that is offensive for any
                reason, you are free to contact and inform us any moment. We
                will consider requests to remove links but we are not obligated
                to or so or to respond to you directly. <br /> <br />
                We do not ensure that the information on this website is
                correct, we do not warrant its completeness or accuracy; nor do
                we promise to ensure that the website remains available or that
                the material on the website is kept up to date.
              </div>
            </div>
          </div>
        </Base>
      </div>
    </>
  );
};

export default Terms;
